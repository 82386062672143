import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SecurityImg from '../../../assets/images/security-gates-img.jpg'

class SecurityGates extends React.Component {
	render() {
		const siteTitle = 'Security Gates - Arlington, TX and Dallas-Fort Worth, TX'
		const siteDescription =
			'Commercial Security Gates in Arlington, TX and the Entire Dallas-Fort Worth, TX Area. Call  To Learn More About Our Gate Services'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">Security Gates in Arlington, TX</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-4">
							<LazyLoadImage
								className="img-fluid"
								src={SecurityImg}
								alt="Security Gate"
							/>
						</div>
						<div className="col-md-8">
							<p>
								Security gates are designed to restrict entry to your property
								and ensure the protection of people and assets. Systems that are
								constructed and installed properly, will work efficiently in
								virtually any{' '}
								<Link to="/gates/services/residential-services/">
									residential
								</Link>{' '}
								or commercial setting. We provide security access systems for
								gates to the entire Dallas-Fort Worth metroplex. Please call us
								today for a free estimate, and let us show you why our excellent
								reputation is well deserved.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Service You Can Count On</h4>
							<p>
								Our experts will work with you to design a security gate system
								in Dallas-Fort Worth, TX that is functional, visually appealing
								and meets your desired building specifications. We use
								high-quality{' '}
								<Link to="/blog/gate-access-systems/">gate operators</Link> and
								access control systems to ensure seamless integration and
								provide you with the level of perimeter security you demand.
							</p>
							<p>
								Whatever your gate needs may be, we have the solution. As a
								trusted{' '}
								<Link to="/gates/services/gate-installation/">installer</Link>,
								we offer residential, commercial, and industrial-grade security
								gates, customized to fit your needs, and at a savings you can
								bank on. We understand that no two businesses are alike, and
								know that the best fit is one{' '}
								<Link to="/gates/gate-designs/">custom designed</Link> to meet
								your specific needs.
							</p>
							<p>
								We will take your gate dimensions and help you order exactly the
								accessories, posts, hinges, latches, and other items required.
								We have all the information at our fingertips, so call today to
								assure you receive everything needed and avoid costly delays.
								Whether you need a single security gate or several gates for an
								extensive fencing system for your business, you can count on us
								to assist you in every way.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Experienced Installers</h4>
							<p>
								We are one of the best commercial security gate installers in
								Arlington, TX and the Dallas-Fort Worth metroplex, and provide
								fast and competent installation and{' '}
								<Link to="/gates/services/gate-repair/">repair</Link>. We can
								dispatch and expert immediately to provide service and ensure
								safe and trouble-free operation at all times.
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Contact Us For A Free Consultation</h4>
							<p>
								Business owners, contractors, and builders throughout
								Dallas-Fort Worth have been relying on us since 2002. We design
								and install not only to protect your property, but more
								importantly, to protect your people. Call us at to discuss your
								needs and to learn more about your options.
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default SecurityGates

export const SecurityGatesPageQuery = graphql`
	query SecurityGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
